import React from "react"
import { graphql } from "gatsby"

//import { languages as langs } from "../settings"

import Seo from "../parts/seo"
import Layout from "../parts/layout"
import Row from "../parts/row"

import HeadingUnderline from "../parts/headingUnderline"

const langs = [
  {
    langcode: "en",
    label: "English",
  },
  {
    langcode: "ar",
    label: "عربى",
  },
  {
    langcode: "el",
    label: "Ελληνικά",
  },
  {
    langcode: "es",
    label: "Español",
  },
  {
    langcode: "gl",
    label: "Galego",
  },
  {
    langcode: "he",
    label: "עִברִית",
  },
  {
    langcode: "hu",
    label: "Hungarian",
  },
  {
    langcode: "nl",
    label: "Nederlands",
  },
  {
    langcode: "pl",
    label: "Polski",
  },
  {
    langcode: "pt-pt",
    label: "Português",
  },
  {
    langcode: "ro",
    label: "Română",
  },
  {
    langcode: "sv",
    label: "Svenska",
  },
]


const getPercentage = (number) => {
  return Math.round(number * 1000) / 10;
}

const StatusPage = ({ data }) => {
  const title = "translation status"

  const pages = data.allSitePage.nodes
  const terms = [
    ...data.allTaxonomyTermSteamSubjects.nodes,
    ...data.allTaxonomyTermRoles.nodes,
    ...data.allTaxonomyTermLocations.nodes,
    ...data.allTaxonomyTermEngagement.nodes,
    ...data.allTaxonomyTermLsCoreSubject.nodes,
    ...data.allTaxonomyTermLuFormat.nodes,
    ...data.allTaxonomyTermManagementSkills.nodes,
    ...data.allTaxonomyTermPhase.nodes,
    ...data.allTaxonomyTermSkills.nodes,
    ...data.allTaxonomyTermSoftSkills.nodes
  ]

  const languages = langs.map(language => {
    return {
      page_abs: 0,
      page_rel: 0,
      ls_abs: 0,
      ls_rel: 0,
      lu_abs: 0,
      lu_rel: 0,
      term_abs: 0,
      term_rel: 0,
      ...language
    }
  })

  pages.forEach(page => {
    const languageToAdd = languages.find(language => language.langcode === page.pageContext.i18n.language)

    if(page.component.includes("page.js")) languageToAdd.page_abs++
    if(page.component.includes("learning-scenario.js")) languageToAdd.ls_abs++
    if(page.component.includes("learning-unit.js")) languageToAdd.lu_abs++
  })

  terms.forEach(term => {
    const languageToAdd = languages.find(language => language.langcode === term.langcode)

    languageToAdd.term_abs++
  })

  languages[0].page_abs = 7

  languages.forEach(language => {
    if(language.page_abs) language.page_rel = language.page_abs / languages[0].page_abs
    if(language.ls_abs) language.ls_rel = language.ls_abs / languages[0].ls_abs
    if(language.lu_abs) language.lu_rel = language.lu_abs / languages[0].lu_abs
    if(language.term_abs) language.term_rel = language.term_abs / languages[0].term_abs
  })


  return (
    <Layout>

      <Seo title={ title } />
      <Row classes={["wrapper-m"]}>

        <HeadingUnderline title={ title } classes={["center"]} />
        <div className="pane pad-m">
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>language</th>
                <th>pages</th>
                <th>learning scenarios</th>
                <th>learning units</th>
                <th>terms</th>
              </tr>
            </thead>
            <tbody>
              { languages.map(language => <tr key={language.langcode}>
                <td>{ language.label }</td>
                <td style={{ backgroundColor: `rgba(124, 224, 176, ${language.page_rel})` }}>
                  <span className="number">{ language.page_abs }</span>
                  <span className="percentage">{ getPercentage(language.page_rel) }%</span>
                </td>
                <td style={{ backgroundColor: `rgba(124, 224, 176, ${language.ls_rel})` }}>
                  <span className="number">{ language.ls_abs }</span>
                  <span className="percentage">{ getPercentage(language.ls_rel) }%</span>
                </td>
                <td style={{ backgroundColor: `rgba(124, 224, 176, ${language.lu_rel})` }}>
                  <span className="number">{ language.lu_abs }</span>
                  <span className="percentage">{ getPercentage(language.lu_rel) }%</span>
                </td>
                <td style={{ backgroundColor: `rgba(124, 224, 176, ${language.term_rel})` }}>
                  <span className="number">{ language.term_abs }</span>
                  <span className="percentage">{ getPercentage(language.term_rel) }%</span>
                </td>
              </tr>) }
            </tbody>
          </table>
        </div>
      </Row>

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSitePage {
      nodes {
        path
        component
        pageContext
      }
    }
    allTaxonomyTermSteamSubjects {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermRoles {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermLocations {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermEngagement {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermLsCoreSubject {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermLuFormat {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermManagementSkills {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermPhase {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermSkills {
      nodes {
        name
        langcode
      }
    }
    allTaxonomyTermSoftSkills {
      nodes {
        name
        langcode
      }
    }
  }
`

export default StatusPage
